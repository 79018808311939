import React from "react"
import st from "./css/drawer.module.scss"
import Svg from "./svg"
import classNames from "classnames"

export default function Drawer({
  children,
  title,
  open,
  onClose,
  maskClosable,
  width,
  height,
  style = {},
  placement = "bottom",
}) {
  if (!open) return null

  return (
    <div className={classNames(st.drawer, st[placement])}>
      <div className={st.mask} onClick={maskClosable ? onClose : null}></div>
      <div className={st.inner} style={{ width, height, ...style }}>
        <div className={st.header}>
          <div className={st.title}>{title}</div>
          <div className={st.close} onClick={onClose}>
            <Svg id="close" svgClass={st.closeIcon} />
          </div>
        </div>
        <div className={st.body}>{children}</div>
      </div>
    </div>
  )
}
