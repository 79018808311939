import React from "react"
// import { Link } from "gatsby"
// import PropTypes from "prop-types"
import st from "./css/usersSay.module.scss"
import classNames from "classnames"

import sayData from "../json/usersSay.json"
import { CDNBASE } from "../utils/maps"
import Svg from "./Svg"

class UsersSay extends React.Component {
  state = {
    cur: 0,
    prev: -1,
    next: 1,
  }
  componentDidMount() {
    this.timeID = setTimeout(this.play, 15000)
  }
  componentWillUnmount() {
    clearTimeout(this.timeID)
  }

  play = () => {
    const cur = this.state.cur + 1
    const len = sayData.length
    this.setState({
      cur: cur < len ? cur : 0,
      prev: cur - 1 >= 0 ? cur - 1 : len - 1,
    })
    this.timeID = setTimeout(this.play, 10000)
  }

  render() {
    const { cur, prev } = this.state

    return (
      <div className={st.banner}>
        <div
          className={st.bg}
          style={{ backgroundImage: `url(${CDNBASE}/home/cases/bd-wall.jpg)` }}
        ></div>
        {sayData.map((v, i) => (
          <div
            className={classNames(
              st.item,
              { [st.cur]: cur === i },
              { [st.prev]: prev === i }
            )}
            key={v.name + i}
          >
            <div className={st.sayWrap}>
              <div className={st.say}>
                <div className={st.words}>{v.say}</div>
              </div>
              <Svg svgClass={st.arrow} id="round_arrow" />
            </div>
            <div
              className={st.avatar}
              style={{
                backgroundImage: `url(${CDNBASE}/home/cases/users.jpg)`,
                backgroundPositionY:
                  v.avatar * (100 / (sayData.length - 1)) + "%",
              }}
            ></div>
            <div className={st.name}>{v.name}</div>
            <div className={st.title}>{v.title}</div>
          </div>
        ))}
      </div>
    )
  }
}

// UsersSay.propTypes = {
//   data: PropTypes.array,
// }

// UsersSay.defaultProps = {
//   data: [],
// }

export default UsersSay
