import React, {useState, useEffect} from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
// import Image from "../components/image"
import SEO from "../components/seo"
import RoleFeature from "../components/roleFeature"
import KukeFeature from "../components/kukeFeature"
import CatNav from "../components/catnav"
import UsersSay from "../components/usersSay"
import Banner from "../components/banner"
import MenuItems from "../components/menuItems"
import { HomeSvgs, CasesSvgs, PostListSvgs } from "../components/svgs"
import { CDNBASE } from "../utils/maps"
import advData from "../json/advance.json"
import st from "./css/home.module.scss"
import kukeFeatures from "../json/kuke-features.json"
import roleFeatures from "../json/role-features.json"

const IndexPage = function () {
  const [isMobile, setIsMobile] = useState(false)
  useEffect(() => {
    if (typeof document !== "undefined") {
      setIsMobile(document.documentElement.clientWidth <= 1140)
    }
  }, [])

  return (
    <Layout cur="/" stickyHeader>
      <SEO
        title="约课小程序"
        keywords={[
          "约课小程序",
          "舞蹈约课",
          "瑜伽约课", 
          "少儿培训约课",
          "课表排课系统",
          "会员管理系统",
          "在线约课平台",
          "培训机构管理系统"
        ]}
        description="酷课约课系统为瑜伽馆、舞蹈工作室、少儿培训机构提供专业的会员管理、课程预约、在线支付、营销推广一体化解决方案。支持微信小程序约课、会员管理、课表排期、在线支付等核心功能。"
      />
      <HomeSvgs />
      <CasesSvgs />
      <PostListSvgs />

      <div
        className={st.banner}
        style={{ backgroundImage: `url(${CDNBASE}/home/banner/1.png)` }}
      >
        <h1>为各类培训机构量身定制的会员管理、在线营销、约课系统</h1>
        <div className={st.link}>
          <Link
            className="btn-pri btn-large"
            target="_blank"
            to="https://kukecloud.com/adm/"
          >
            开始免费试用
          </Link>
        </div>
        <div className={st.qrcode}>
          <img
            alt="酷课公众号"
            src={CDNBASE + "/src/qrcode/user-intro-ying.png"}
          />
          <h5>长按识别，开始免费试用</h5>
        </div>
        <div
          className={st.ill}
          style={{ backgroundImage: `url(${CDNBASE}/home/banner/1.1.png?001)` }}
        ></div>
      </div>
      <MenuItems
        icon="kuke_features/icons/roles.png?112"
        iconSize={[122, 144]}
        list={roleFeatures.map(v => ({ id: v.icon, name: v.title }))}
        title="酷课约课系统怎么用？"
        // genLink={v => `/#${v.icon}-${v}`}
        isMobile={isMobile}
        styles={{ flexWrap: "nowrap" }}
      />
      <MenuItems
        icon="kuke_features/icons/features.png?777"
        iconSize={[84, 134]}
        list={kukeFeatures.map(v => ({ id: v.icon, name: v.title }))}
        title="酷课约课系统有哪些功能？"
        isMobile={isMobile}
        idPre="intro-"
      />
      <KukeFeature isMobile={isMobile} />
      <RoleFeature isMobile={isMobile} />

      <div>
        {advData.slice(0, 2).map((v, i) => (
          <Banner key={i} v={v} i={i} />
        ))}
      </div>

      <UsersSay />

      <div>
        <h2 className="f-tc f-mt-3">哪些行业在使用酷课约课系统？</h2>
        <CatNav urlBase="/cases" />
      </div>
    </Layout>
  )
}

export default IndexPage
