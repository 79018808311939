import React from "react"
// import { Link } from "gatsby"
import PropTypes from "prop-types"

import cases from "../json/cases.json"
import { CDNBASE } from "../utils/maps"
import st from "./css/catnav.module.scss"
// import classNames from 'classnames'

const CatNav = ({ urlBase }) => {
  return (
    <div className={st.iconNav}>
      {cases.map((v, i) => (
        <a
          key={v.icon}
          href={urlBase + "#" + v.icon}
          className={st.iconNavItem}
        >
          <div className={st.iconNavItemWrap} onClick={() => {}}>
            <div
              className={st.iconNavIcon}
              style={{
                backgroundImage: `url(${CDNBASE}/home/cases/cats.png?001)`,
                backgroundPositionX: i * (100 / (cases.length - 1)) + "%",
              }}
            ></div>
            <div
              className={st.mask}
              style={{
                backgroundImage: `url(${CDNBASE}/home/cases/fonts.png?001)`,
                backgroundPositionX: i * (100 / (cases.length - 1)) + "%",
              }}
            ></div>
            <div className={st.iconNavTitle}>
              <h3>{v.title}</h3>
            </div>
          </div>
        </a>
      ))}
    </div>
  )
}

CatNav.propTypes = {
  cases: PropTypes.array,
  urlBase: PropTypes.string,
}

CatNav.defaultProps = {
  cases: [],
  urlBase: "",
}

export default CatNav
